'use client'

import { useParams } from 'next/navigation'
import React, { useEffect, useState } from 'react'
import { SWRConfig } from 'swr'
import { ThemeProvider } from 'styled-components'
import { lightTheme } from 'theme/theme'
import { GlobalStyles } from 'theme/global-styles'
import { StyledRegistry } from 'lib/styled-registry'
import { ContextProvider } from 'providers/context-provider/context-provider'
import { Language } from 'generated/sdk'
import { ErrorBoundary } from 'services/sentry'
import { ReadonlyHeaders } from 'next/dist/server/web/spec-extension/adapters/headers'
import { getLocationOrigin, getUserAgent } from 'utils/url'
import UAParser from 'ua-parser-js'

type ProviderWrapperProps = {
	headers: ReadonlyHeaders
	children: any
}

const ProviderWrapper = ({ headers, children }: ProviderWrapperProps) => {
	const params = useParams()
	const [userAgent, setUserAgent] = useState<string>('')
	const hasParams = params && params.slug && params.slug.length
	const firstParamIsLang = hasParams ? Object.values(Language).includes(params.slug[0] as Language) : false
	const language: Language = hasParams && firstParamIsLang ? (params.slug[0] as Language) : Language.Pt
	const origin = getLocationOrigin(headers)
	const device = new UAParser(userAgent).getDevice()
	console.log('🚀 ~ ProviderWrapper ~ device:', device)

	// Set the document's language
	useEffect(() => {
		if (document) {
			document.documentElement.lang = language
		}
	}, [language])

	useEffect(() => {
		if (navigator) {
			setUserAgent(getUserAgent(navigator, headers))
		}
	}, [setUserAgent, headers])

	const AppContent = (
		<StyledRegistry>
			<ThemeProvider theme={lightTheme}>
				<GlobalStyles />
				<SWRConfig
					value={{
						shouldRetryOnError: true,
						errorRetryCount: 2,
						focusThrottleInterval: 60 * 1000, // 1 minute
						refreshInterval: 7 * 60 * 1000, // 7 minutes
					}}>
					<ContextProvider language={language} origin={origin} isMobileDevice={device.type === 'mobile'}>
						{children}
					</ContextProvider>
				</SWRConfig>
			</ThemeProvider>
		</StyledRegistry>
	)

	return ErrorBoundary ? <ErrorBoundary>{AppContent}</ErrorBoundary> : AppContent
}

export default ProviderWrapper
